var $ = require('jquery');
window.jQuery = $;
window.$ = $;

// load CSRF cookie
$(document).ready(function() {
    // get CSRF cookie as JSON and save it as global variable

    $.get("/api/getcsrf", function(data) {
        window.csrfTokenName = data.csrfTokenName;
        window.csrfTokenValue = data.csrfTokenValue;
    }, "json");
});

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};


function showError(){
    $('.freeform-row-error').removeClass("d-none");
}
function hideError(){
    $('.freeform-row-error').addClass("d-none");
}
function disableSubmit(){
    $('button.submit').addClass("disabled");
    $('button.submit').attr("disabled", true);
}
function enableSubmit(){
    $('button.submit').removeClass("disabled");
    $('button.submit').attr("disabled", false);
}


function scrollToForm(id) {
    // Scroll to a certain element
    //console.log("scrollToForm: " + id);
    document.querySelector('#my-form').scrollIntoView({
        behavior: 'smooth'
    });
    event.preventDefault();
    event.stopPropagation();
}


$(document).ready(function () {

    $('.filepond').removeClass('form-control');

    if($("#empty-form").length > 0) {
        $('#my-form').trigger("reset");
    }

    $( "#my-form" ).submit(function( event ) {

        // dynamisch pflichtfelder abfragen
        var formtyp = $('#form-input-schadenursache').val();
        //console.log("formtyp set: " + formtyp);

        if (formtyp == "Elementarschaden") {

            if ( $("#form-input-elementarschaden-0").is(':checked')
                || $("#form-input-elementarschaden-1").is(':checked')
                || $("#form-input-elementarschaden-2").is(':checked')
                || $("#form-input-elementarschaden-3").is(':checked')
                || $("#form-input-elementarschaden-4").is(':checked')
            ) {
            } else {
                scrollToForm('form-input-elementarschaden');
            }

            if ( $("#form-input-beschaedigteGebaeudeteile-0").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-1").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-2").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-3").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-4").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-5").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-6").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-7").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-8").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-9").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-10").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-11").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-12").is(':checked')
                || $("#form-input-beschaedigteGebaeudeteile-13").is(':checked')
            ) {
            } else {
                scrollToForm('form-input-beschaedigteGebaeudeteile');
            }

        } else if (formtyp == "Feuerschaden") {

            if ( $("#form-input-feuerschaden-0").is(':checked')
                || $("#form-input-feuerschaden-1").is(':checked')
                || $("#form-input-feuerschaden-2").is(':checked')
                || $("#form-input-feuerschaden-3").is(':checked')
                || $("#form-input-feuerschaden-4").is(':checked')
            ) {
            } else {
                scrollToForm('form-input-feuerschaden');
            }

        }

        const myForm = document.getElementById("my-form");
        // uploaderror, zu gross oder zu viele
        if (typeof formError !== 'undefined') {
            if (formError.value != "") {
                showError();
                event.preventDefault();
                event.stopPropagation();
            }
        }

    });

    $(window).scroll(function() {
        if ($(window).scrollTop() > 200) {
            $('#topcontrol').addClass('active');
        } else {
            $('#topcontrol').removeClass('active');
        }
    });

    /*
    $("#upload").fileinput({
        language: "de",
        dropZoneEnabled: true,
        uploadAsync: true,
        showUpload: false,
        showCaption: false,
        showRemove: false,
        maxFileCount: 5,
        maxFileSize: 6400,
        browseLabel: "",
        browseIcon: "<img src=\"/icons/folder-open-solid.svg\">"
    }).on('fileerror', function(event, data, msg) {
        $('#form-error').val('filesizetobig');
        showError();
        disableSubmit();

        // console.log(data.id);
        // console.log(data.index);
        // console.log(data.file);
        // console.log(data.reader);
        // console.log(data.files);
        // alert(msg);
    });
    */

    /*
    $(  ".file-input .file-preview .fileinput-remove," +
        " .kv-fileinput-error.file-error-message button," +
        ".file-drop-zone .clickable," +
        ".btn-file img," +
        ".btn-file input").click(function(event) {

        $('#form-error').val('');
        hideError();
        enableSubmit();

    });

    $(".file-input .file-preview .fileinput-remove span").html("Alle Dokumente löschen");
*/

    // externe links & links auf downloads
    $('main .component-headlineText a, main .component-faq a, .full-checkbox a').each(function() {

        var a = new RegExp('/' + window.location.host + '/');
        if(!a.test(this.href)) {
            $(this).addClass("link-extern");
            $( this ).append( "<svg class=\"svg-inline--fa fa-external-link-alt fa-w-18\" aria-hidden=\"true\" focusable=\"false\" data-prefix=\"fas\" data-icon=\"external-link-alt\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 576 512\" data-fa-i2svg=\"\"><path fill=\"currentColor\" d=\"M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z\"></path></svg>" );
            $(this).click(function(event) {
                event.preventDefault();
                event.stopPropagation();
                window.open(this.href, '_blank');
            });
        }

        var dl = new RegExp('/assets/content/files/');
        if(dl.test(this.href)) {
            $(this).addClass("link-download");
            $(this).add("download");

            var ext = this.href.substr(this.href.lastIndexOf('.') + 1);

            if (ext == "pdf") {
                $( this ).prepend( "<svg class=\"svg-inline--fa fa-file-pdf fa-w-12 color-info\" aria-hidden=\"true\" focusable=\"false\" data-prefix=\"far\" data-icon=\"file-pdf\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 384 512\" data-fa-i2svg=\"\"><path fill=\"currentColor\" d=\"M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z\"></path></svg>" );

            } else if (ext == "xls" || ext == "xlsm") {
                $( this ).prepend( "<svg class=\"svg-inline--fa fa-file-excel fa-w-12 color-info\"  aria-hidden=\"true\" focusable=\"false\" data-prefix=\"far\" data-icon=\"file-excel\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 384 512\"><path fill=\"currentColor\" d=\"M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z\"></path></svg>" );

            } else if (ext == "doc" || ext == "docx") {
                $( this ).prepend( "<svg class=\"svg-inline--fa fa-file-word fa-w-12 color-info\" aria-hidden=\"true\" focusable=\"false\" data-prefix=\"far\" data-icon=\"file-word\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 384 512\"><path fill=\"currentColor\" d=\"M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z\"></path></svg>" );
            } else {
                $( this ).prepend( "<svg class=\"svg-inline--fa fa-file fa-w-12 color-info\" aria-hidden=\"true\" focusable=\"false\" data-prefix=\"far\" data-icon=\"file\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 384 512\"><path fill=\"currentColor\" d=\"M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z\"></path></svg>" );
            }
            $(this).click(function(event) {
                event.preventDefault();
                event.stopPropagation();
                window.open(this.href, '_blank');
            });

        }

    });


    // filter mit strasse je nach ort setzen
    ajaxStrasseL();
    ajaxStrasseV();
    ajaxStrasseS();

    // filter mit strasse je nach ort setzen
    $( "#form-input-lagerungsadresse" ).change(function() {
        //console.log("#form-input-lagerungsadresse changed");
        $('#form-input-strasseLagerungsadresseHidden').val("");
        ajaxStrasseL();
    });
    $( "#form-input-verkaufsadresse" ).change(function() {
        //console.log("#form-input-verkaufsadresse changed");
        $('#form-input-strasseVerkaufsadresseHidden').val("");
        ajaxStrasseV();
    });
    $( "#form-input-schadenort" ).change(function() {
        //console.log("#form-input-schadenort changed");
        $('#form-input-strasseSchadensortHidden').val("");
        ajaxStrasseS();
    });
    $( "#form-input-liegenschaftsort" ).change(function() {
        //console.log("#form-input-schadenort changed");
        $('#form-input-strasseLiegenschaftsortHidden').val("");
        ajaxStrasseM();
    });

});


$( "#form-input-strasseLiegenschaftsort" ).change(function() {
    //console.log("#form-input-strasseLagerungsadresse changed");
    var strasse = $( "#form-input-strasseLiegenschaftsort option:selected" ).text();
    $('#form-input-strasseLiegenschaftsortHidden').val(strasse);
});

$( "#form-input-strasseLagerungsadresse" ).change(function() {
    //console.log("#form-input-strasseLagerungsadresse changed");
    var strasse = $( "#form-input-strasseLagerungsadresse option:selected" ).text();
    $('#form-input-strasseLagerungsadresseHidden').val(strasse);
});

$( "#form-input-strasseVerkaufsadresse" ).change(function() {
    //console.log("#form-input-strasseVerkaufsadresse changed");
    var strasse = $( "#form-input-strasseVerkaufsadresse option:selected" ).text();
    $('#form-input-strasseVerkaufsadresseHidden').val(strasse);
});

$( "#form-input-strasseSchadensort" ).change(function() {
    //console.log("#form-input-strasseSchadensort changed");
    var strasse = $( "#form-input-strasseSchadensort option:selected" ).text();
    $('#form-input-strasseSchadensortHidden').val(strasse);
});



//Lagerungsadresse Feuerwerk
function ajaxStrasseL() {
    //console.log("in ajaxStrasseL");
    if ( $( "#form-input-strasseLagerungsadresse" ).length ) {
        var selectedCity = $( "#form-input-lagerungsadresse option:selected" ).val();
        var ajaxUrl = "/api/strassenVerzeichnis?city=" + selectedCity;
        $.ajax({type: "GET", url: ajaxUrl, success: function(result){
            $('#form-input-strasseLagerungsadresse').html(result);

            if ( $( ".freeform-form-has-errors" ).length &&  $('#form-input-strasseLagerungsadresseHidden').val() != "") {
                var strasseSet = $('#form-input-strasseLagerungsadresseHidden').val();
                //console.log("strasseSet in ajax: " + strasseSet);
                $( "#form-input-strasseLagerungsadresse" ).val(strasseSet).change();
            } else {
                //console.log("strasse not set in ajax ");
                var strasse = $( "#form-input-strasseLagerungsadresse option:selected" ).text();
                $('#form-input-strasseLagerungsadresseHidden').val(strasse);
            }

        }});
    }
}

//Verkaufsadresse Feuerwerk
function ajaxStrasseV() {
    //console.log("in ajaxStrasseV");
    if ( $( "#form-input-strasseVerkaufsadresse" ).length ) {
        var selectedCity = $( "#form-input-verkaufsadresse option:selected" ).val();
        var ajaxUrl = "/api/strassenVerzeichnis?city=" + selectedCity;
        $.ajax({type: "GET", url: ajaxUrl, success: function(result){
            $('#form-input-strasseVerkaufsadresse').html(result);

            if ( $( ".freeform-form-has-errors" ).length &&  $('#form-input-strasseVerkaufsadresseHidden').val() != "") {
                var strasseSet = $('#form-input-strasseVerkaufsadresseHidden').val();
                //console.log("strasseSet in ajax: " + strasseSet);
                $( "#form-input-strasseVerkaufsadresse" ).val(strasseSet).change();
            } else {
                //console.log("strasse not set in ajax ");
                var strasse = $( "#form-input-strasseVerkaufsadresse option:selected" ).text();
                $('#form-input-strasseVerkaufsadresseHidden').val(strasse);
            }

        }});
    }
}

//Schadensort Adresse
function ajaxStrasseS() {
    //console.log("in ajaxStrasseS");
    if ( $( "#form-input-strasseSchadensort" ).length ) {
        var selectedCity = $( "#form-input-schadenort option:selected" ).val();
        var ajaxUrl = "/api/strassenVerzeichnis?city=" + selectedCity;
        $.ajax({type: "GET", url: ajaxUrl, success: function(result){
                $('#form-input-strasseSchadensort').html(result);
                if ( $( ".freeform-form-has-errors" ).length &&  $('#form-input-strasseSchadensortHidden').val() != "") {
                    var strasseSet = $('#form-input-strasseSchadensortHidden').val();
                    //console.log("strasseSet in ajax: " + strasseSet);
                    $( "#form-input-strasseSchadensort" ).val(strasseSet).change();
                } else {
                    //console.log("strasse not set in ajax ");
                    var strasse = $( "#form-input-strasseSchadensort option:selected" ).text();
                    $('#form-input-strasseSchadensortHidden').val(strasse);
                }
            }});
    }
}

//Liegenschaftsort Adresse (Mutation & Bauvorhaben)
function ajaxStrasseM() {
    //console.log("in ajaxStrasseS");
    if ( $( "#form-input-strasseLiegenschaftsort" ).length ) {
        var selectedCity = $( "#form-input-liegenschaftsort option:selected" ).val();
        var ajaxUrl = "/api/strassenVerzeichnis?city=" + selectedCity;
        $.ajax({type: "GET", url: ajaxUrl, success: function(result){
                $('#form-input-strasseLiegenschaftsort').html(result);
                if ( $( ".freeform-form-has-errors" ).length &&  $('#form-input-strasseLiegenschaftsortHidden').val() != "") {
                    var strasseSet = $('#form-input-strasseLiegenschaftsortHidden').val();
                    //console.log("strasseSet in ajax: " + strasseSet);
                    $( "#form-input-strasseLiegenschaftsort" ).val(strasseSet).change();
                } else {
                    //console.log("strasse not set in ajax ");
                    var strasse = $( "#form-input-strasseLiegenschaftsort option:selected" ).text();
                    $('#form-input-strasseLiegenschaftsortHidden').val(strasse);
                }
            }});
    }
}

function cookieConsentCheck() {
    window.addEventListener("DOMContentLoaded", (evt) => {
        if(localStorage.getItem('cookiePreferences') === null) {
            localStorage.setItem('cookiePreferences', '');
        }
        const cookieAlert = $('#cookieAlert').alert();
        const cookieModal = $('#cookieModal');
        const checkBoxes = document.getElementsByName("cookiePreference");
        const saveButtons = document.querySelectorAll('.save-cookies');
    
        function setCheckboxFromLocalstorage() {
            if (localStorage.getItem("cookieConsent") && localStorage.getItem("cookiePreferences")) {
                const storedValues = localStorage.getItem("cookiePreferences").split(",");
                checkBoxes.forEach((el) => {
                    if (storedValues.includes(el.value)) {
                        el.checked = true;
                    }
                });
            } else {
                // set default values true for cookie consent
                checkBoxes.forEach((el) => {
                    el.checked = true;
                });
            }
    
        }
    
        function getCookiePreferences() {
            const selectedCboxes = Array.prototype.slice.call(checkBoxes).filter((cb) => cb.checked == true);
            const selectedValues = [];
            selectedCboxes.forEach((e) => {
                selectedValues.push(e.value);
            });
            return selectedValues;
        }
    
        function activateScripts(buttonScope = false) {
            const storedValues = localStorage.getItem("cookiePreferences").split(",");
            storedValues.forEach((category) => {
                const cookieScripts = document.querySelectorAll(`[data-cookietype="${ category }"]`);
                cookieScripts.forEach((script) => {
                    if (script.dataset.type !== script.type) {
                        const newNode = document.createElement(script.tagName);
                        newNode.innerHTML = script.innerHTML;
                        for (let i = 0, max = script.attributes.length - 1; i <= max; ++i) {
                            newNode.setAttribute(script.attributes[i].name,script.attributes[i].value);
                        }
                        newNode.setAttribute('type', script.dataset.type);
                        script.parentNode.insertBefore(newNode, script);
                        script.parentNode.removeChild(script);
                    }
                });
            });
            if (buttonScope) {
                document.querySelector('#cookieAlert').classList.add('d-none');
                cookieModal.modal('hide');
            }
        }
    
        function saveCookies() {
            localStorage.setItem("cookieConsent", true);
            localStorage.setItem("cookiePreferences", getCookiePreferences());
    
            activateScripts(true);
        }
    
        setCheckboxFromLocalstorage();
        activateScripts();
    
        saveButtons.forEach(el => { el.addEventListener('click', () => { saveCookies() }) });
    
        if (localStorage.getItem("cookieConsent") === null) {
            document.getElementById("cookieAlert").classList.remove("d-none");
        }
    
    });
}

cookieConsentCheck();
